@import "@styles-v2/common";

.container {
  //
}

.indicator {
  &::after {
    content: " ";
    display: block;
    border-radius: 50%;
    animation: spinner-keyframes 1.2s linear infinite;
  }
}

.large {
  display: inline-block;
  width: 80px;
  height: 80px;

  &::after {
    $color: c(symbol);

    width: 64px;
    height: 64px;
    margin: 8px;
    border: 6px solid $color;
    border-color: $color transparent $color transparent;
  }
}

.small {
  display: inline;
  width: 32px;
  height: 32px;

  &::after {
    $color: c(symbol);

    width: 24px;
    height: 24px;
    margin: 4px;
    border: 3px solid $color;
    border-color: $color transparent $color transparent;
  }
}

.white {
  &::after {
    $color: #fff;

    border-color: $color transparent $color transparent;
  }
}

.primary {
  &::after {
    $color: c(primary-700);

    border-color: $color transparent $color transparent;
  }
}

@keyframes spinner-keyframes {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
