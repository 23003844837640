// ============================================================
// Colors
// ============================================================
// black
$black0: #000;
$black1: #212121;
$black2: #363636;
$black3: #222;
// build を通すため改行を入れる

$black4: #1e1e1e;
$black5: #323232;
$black: $black0;

// white
$white0: #fff;
$white: $white0;

// gray
$gray1: #7a7a7a;
$gray2: #707070;
$gray3: rgba($black2, .2);
$gray4: #d1d1d1;
$gray5: #e7e7e7;
$gray6: #f4f4f4;
$gray7: #f9f9f9;
$gray8: #f1f1f1;
$gray9: #999;
$gray10: #a6a6a6;
$gray11: rgba($black1, .6);
$gray12: #f3f3f3;
$gray13: #e8e8e8;
$gray14: #e0e0e0;
$gray15: rgba($black3, .5);
$gray16: #d2d2d2;
$gray17: #f2f2f2;
$gray18: #f4f4f4;
$gray19: #e6e6e6;
$gray20: #ddd;
$gray21: #efefef;
$gray22: #959595;
$gray23: #eaeaea;
$gray24: rgba($black5, .06);
$gray25: #747474;
$gray26: #d3d3d3;
$gray27: #505050;
$gray28: #a2a2a2;
$gray29: #c8c8c8;
$gray30: #494949;
$gray31: #434343;
$gray32: #f5f5f5;
$gray33: #7b7b7b;
$gray34: #d9d9d9;
$gray35: #e9e9e9;
$gray36: #fafafa;
$gray37: #666;
$gray38: #444;
$gray39: #eaeaea;
$gray: $gray1;

// others
$red1: #e61842;
$red2: #ed4758;
$red3: #cb2431;
$red4: #ef1f1f;
$red5: #d7373f;
$red6: #ffebee;
$red7: #d11922;
$red8: #ea3f60;
$green: #02c77e;
$green1: #33ab84;
//$blue1: #0070f3;
//$blue2: #006cff;
//$blue3: #1473e6;
//$blue4: #1069ec;
// V2 と統一するために primary 系の色を変更
$blue1: #6941c6; // primary-700
$blue2: #6941c6; // primary-700
$blue3: #7f56d9; // primary-600
$blue4: #7f56d9; // primary-600
$blue5: #e3f3ff;
$blue6: #2680eb; // 使われてない
//$blue7: #1069ec;
$blue7: #7f56d9;
$blue8: #551acc;
$yellow1: #e2b30d;
$yellow2: #fff3e0;
$yellow3: #ef6902;
$yellow: $yellow1;
$transparent: transparent;

// ============================================================
// Typography
// ============================================================
$line-height-lg: 2.25;
$line-height-sm: 1.5;
$line-height-base: 1.75;

$font-size-root: 16px;

$font-family-base: (
  'Poppins',
  'Noto Sans JP',
  -apple-system,
  // Mac, iOS の英数字（ヒラギノのアルファベット汚い）
  BlinkMacSystemFont,
  // Mac, iOS で Chrome, Opera の英数字
  'Original Yu Gothic',
  // Windows10 の日本語
  '游ゴシック',
  'Yu Gothic',
  // Windows10 で Edge のときの日本語
  YuGothic,
  'Hiragino Sans',
  // Mac (El Capitan 以上) の日本語
  'Hiragino Kaku Gothic ProN',
  // Mac (El Capitan 未満) の日本語
  Meiryo,
  // Windows7, 8 の日本語
  sans-serif // フォールバック
);

$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-medium: 500;
$font-weight-semiBold: 600;
$font-weight-bold: 700;
$font-weight-heavy: 900;
$font-weight-regular: $font-weight-normal; // alias

$font-weight-base: $font-weight-normal;

// ============================================================
// Grid
// ============================================================
$grid-gutter-width: 30px;
$grid-gutter-half-width: $grid-gutter-width / 2;

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 1092px,
  xl: 1296px,
);

$container-max-widths: (
  sm: 767px,
  md: 1030px,
  lg: 1030px,
  xl: 1246px,
);

$mypage-container-max-widths: (
  md: 100%,
  lg: 900px,
  xl: 1000px,
);

// ============================================================
// Header
// ============================================================
$header-height: 64px;

// ============================================================
// z-index
// ============================================================
$outside-overlay-z-index: 2000;
